import React, { Component } from 'react';
import './AboutMe.css';
import './Background.css';
import { spline } from "@georgedoescode/spline";
import SimplexNoise from 'simplex-noise';
// import OwlCarousel from 'react-owl-carousel';  
// import 'owl.carousel/dist/assets/owl.carousel.css';  
// import 'owl.carousel/dist/assets/owl.theme.default.css';

import bootstrap from '../../assets/img/skills/bootstrap.png'
import css from '../../assets/img/skills/css.png'
import html from '../../assets/img/skills/html.png'
import jquery from '../../assets/img/skills/jquery.png'
import js from '../../assets/img/skills/js.png'
import less from '../../assets/img/skills/less.webp'
import materialize from '../../assets/img/skills/materialize.png'
import mysql from '../../assets/img/skills/mysql.png'
import php from '../../assets/img/skills/PHP.png'
import python from '../../assets/img/skills/python.png'
import react from '../../assets/img/skills/React.png'
import sass from '../../assets/img/skills/sass.png'
import showcase from '../../assets/img/logo3.png'
import figma from '../../assets/img/skills/figma.png'
import firebase from '../../assets/img/skills/firebase.png'
import jest from '../../assets/img/skills/jest.png'
import mongodb from '../../assets/img/skills/mongodb.png'
import nextjs from '../../assets/img/skills/nextjs.png'
import redux from '../../assets/img/skills/redux.webp'






// https://dev.to/georgedoescode/tutorial-build-a-smooth-animated-blob-using-svg-js-3pne
class AboutMe extends Component {


    // User Info Background Animation
    componentDidMount(){
        
        // our <path> element
        const path = document.querySelector("#pathSvg");
        // used to set our custom property values
        const root = document.documentElement;


        function createPoints() {
        const points = [];
        // how many points do we need
        const numPoints = 6;
        // used to equally space each point around the circle
        const angleStep = (Math.PI * 2) / numPoints;
        // the radius of the circle
        const rad = 75;

        for (let i = 1; i <= numPoints; i++) {
            // x & y coordinates of the current point
            const theta = i * angleStep;

            const x = 100 + Math.cos(theta) * rad;
            const y = 100 + Math.sin(theta) * rad;

            // store the point
            points.push({
            x: x,
            y: y,
            /* we need to keep a reference to the point's original {x, y} coordinates 
            for when we modulate the values later */
            originX: x,
            originY: y,
            // more on this in a moment!
            noiseOffsetX: Math.random() * 1000,
            noiseOffsetY: Math.random() * 1000,
            });
        }

        return points;
        }


        const points = createPoints();


        const simplex = new SimplexNoise();

        // how fast we progress through "time"
        let noiseStep = 0.005;

        function noise(x, y) {
            // return a value at {x point in time} {y point in time}
            return simplex.noise2D(x, y);
        }

        let hueNoiseOffset = 0;

        (function animate() {
            path.setAttribute("d", spline(points, 1, true));
            requestAnimationFrame(animate);

            // for every point...
            for (let i = 0; i < points.length; i++) {
                const point = points[i];

                // return a pseudo random value between -1 / 1 based on this point's current x, y positions in "time"
                const nX = noise(point.noiseOffsetX, point.noiseOffsetX);
                const nY = noise(point.noiseOffsetY, point.noiseOffsetY);
                // map this noise value to a new value, somewhere between it's original location -20 and it's original location + 20
                const x = map(nX, -1, 1, point.originX - 20, point.originX + 20);
                const y = map(nY, -1, 1, point.originY - 20, point.originY + 20);

                // update the point's current coordinates
                point.x = x;
                point.y = y;

                // progress the point's x, y values through "time"
                point.noiseOffsetX += noiseStep;
                point.noiseOffsetY += noiseStep;
            }

            // we want the hue to move a little slower than the rest of the shape
            hueNoiseOffset += noiseStep / 6;
            
            const hueNoise = noise(hueNoiseOffset, hueNoiseOffset);
            const hue = map(hueNoise, -1, 1, 0, 360);

            root.style.setProperty("--startColor", `hsl(${hue}, 100%, 75%)`);
            root.style.setProperty("--stopColor", `hsl(${hue + 60}, 100%, 75%)`);
            // document.body.style.background = `hsl(${hue + 60}, 75%, 5%)`;

        })();

        // map a number from 1 range to another
        function map(n, start1, end1, start2, end2) {
            return ((n - start1) / (end1 - start1)) * (end2 - start2) + start2;
        }


        const skills = [
            {name : 'nextjs',image:nextjs},
            {name : 'React',image:react},
            {name : 'redux',image:redux},
            {name : 'JavaScript',image:js},
            {name : 'mongodb',image:mongodb},
            {name : 'jest',image:jest},
            {name : 'RTL',image:react},
            {name : 'PHP',image:php},
            {name : 'Python',image:python},
            {name : 'firebase',image:firebase},
            {name : 'Mysql',image:mysql},
            {name : 'figma',image:figma},
            {name : 'Html',image:html},
            {name : 'CSS',image:css},
            {name : 'JQuery',image:jquery},
            {name : 'BootStrap',image:bootstrap,},
            {name : 'Sass',image:sass},
            {name : 'Less',image:less},
            {name : 'Materialize',image:materialize},
        ];

        const getSkills = ()=>{
            


            const Skills_Container = document.getElementById('skills_container');
            var cs = getComputedStyle(Skills_Container);

            var paddingX = parseFloat(cs.paddingLeft) + parseFloat(cs.paddingRight);
            var paddingY = parseFloat(cs.paddingTop) + parseFloat(cs.paddingBottom);

            var borderX = parseFloat(cs.borderLeftWidth) + parseFloat(cs.borderRightWidth);
            var borderY = parseFloat(cs.borderTopWidth) + parseFloat(cs.borderBottomWidth);

            // Element width and height minus padding and border
            const MaxWidth = Skills_Container.offsetWidth - paddingX - borderX;
            // elementHeight = element.offsetHeight - paddingY - borderY;



            const Cards = [];



            for (let i = 0; i < skills.length; i++) {

                
                
                let SkillCard = document.createElement('div');
                SkillCard.classList.add('hvr-grow-shadow','skill_card'); // ''
                let image = document.createElement('img');
                let Skill_Desc = document.createElement('p');

                image.setAttribute('src',skills[i].image);
                image.setAttribute('alt',skills[i].name);
                Skill_Desc.innerHTML = skills[i].name;

                SkillCard.append(image);
                SkillCard.append(Skill_Desc);

                Cards.push(SkillCard);

            }

            let flex_row = document.createElement('div');
            flex_row.classList.add('flex_row');

            let Rows = [];

            if(window.innerWidth > 1181){
                console.log('Op',1);
                const remaining = Cards.length % 5;

                for (let i = 0; i < Cards.length; i++) {
                    
                    if( i >= Cards.length - remaining ){
                        flex_row.append(Cards[i]);
                        if(i + 1 == Cards.length){
                            
                            Rows.push(flex_row);
                        }
                    }else{
                        flex_row.append(Cards[i]);

                        if((i + 1) % 5 == 0){
                            Rows.push(flex_row);
                            flex_row = '';
                            flex_row = document.createElement('div');
                            flex_row.classList.add('flex_row')
                        }
                    }
                    
                }
            }else if(window.innerWidth < 1181 && window.innerWidth > 900){
                
                const remaining = Cards.length % 4;

                for (let i = 0; i < Cards.length; i++) {
                    
                    if( i >= Cards.length - remaining ){
                        flex_row.append(Cards[i]);
                        if(i + 1 == Cards.length){
                            
                            Rows.push(flex_row);
                        }
                    }else{
                        flex_row.append(Cards[i]);

                        if((i + 1) % 4 == 0){
                            Rows.push(flex_row);
                            flex_row = '';
                            flex_row = document.createElement('div');
                            flex_row.classList.add('flex_row')
                        }
                    }
                    
                }
            }else if(window.innerWidth < 900 && window.innerWidth > 720){
                
                const remaining = Cards.length % 3;

                for (let i = 0; i < Cards.length; i++) {
                    
                    if( i >= Cards.length - remaining ){
                        flex_row.append(Cards[i]);
                        if(i + 1 == Cards.length){
                            
                            Rows.push(flex_row);
                        }
                    }else{
                        flex_row.append(Cards[i]);

                        if((i + 1) % 3 == 0){
                            Rows.push(flex_row);
                            flex_row = '';
                            flex_row = document.createElement('div');
                            flex_row.classList.add('flex_row')
                        }
                    }
                    
                }
            }else if(window.innerWidth < 720){
                
                const remaining = Cards.length % 2;

                for (let i = 0; i < Cards.length; i++) {
                    
                    if( i >= Cards.length - remaining ){
                        flex_row.append(Cards[i]);
                        if(i + 1 == Cards.length){
                            
                            Rows.push(flex_row);
                        }
                    }else{
                        flex_row.append(Cards[i]);

                        if((i + 1) % 2 == 0){
                            Rows.push(flex_row);
                            flex_row = '';
                            flex_row = document.createElement('div');
                            flex_row.classList.add('flex_row')
                        }
                    }
                    
                }
            }

            window.addEventListener('resize', ()=>{
                Skills_Container.innerHTML = '';
                const screenWidth = window.innerWidth;
                Rows = [];
    
                if(screenWidth > 1181){
                    console.log('Op',1);
                    const remaining = Cards.length % 5;
    
                    for (let i = 0; i < Cards.length; i++) {
                        
                        if( i >= Cards.length - remaining ){
                            flex_row.append(Cards[i]);
                            if(i + 1 == Cards.length){
                                
                                Rows.push(flex_row);
                            }
                        }else{
                            flex_row.append(Cards[i]);
    
                            if((i + 1) % 5 == 0){
                                Rows.push(flex_row);
                                flex_row = '';
                                flex_row = document.createElement('div');
                                flex_row.classList.add('flex_row')
                            }
                        }
                        
                    }
                }else if(screenWidth < 1181 && window.innerWidth > 900){
                    
                    const remaining = Cards.length % 4;
    
                    for (let i = 0; i < Cards.length; i++) {
                        
                        if( i >= Cards.length - remaining ){
                            flex_row.append(Cards[i]);
                            if(i + 1 == Cards.length){
                                
                                Rows.push(flex_row);
                            }
                        }else{
                            flex_row.append(Cards[i]);
    
                            if((i + 1) % 4 == 0){
                                Rows.push(flex_row);
                                flex_row = '';
                                flex_row = document.createElement('div');
                                flex_row.classList.add('flex_row')
                            }
                        }
                        
                    }
                }else if(screenWidth < 900 && window.innerWidth > 720){
                    
                    const remaining = Cards.length % 3;
    
                    for (let i = 0; i < Cards.length; i++) {
                        
                        if( i >= Cards.length - remaining ){
                            flex_row.append(Cards[i]);
                            if(i + 1 == Cards.length){
                                
                                Rows.push(flex_row);
                            }
                        }else{
                            flex_row.append(Cards[i]);
    
                            if((i + 1) % 3 == 0){
                                Rows.push(flex_row);
                                flex_row = '';
                                flex_row = document.createElement('div');
                                flex_row.classList.add('flex_row')
                            }
                        }
                        
                    }
                }else if(screenWidth < 720){
                    
                    const remaining = Cards.length % 2;
    
                    for (let i = 0; i < Cards.length; i++) {
                        
                        if( i >= Cards.length - remaining ){
                            flex_row.append(Cards[i]);
                            if(i + 1 == Cards.length){
                                
                                Rows.push(flex_row);
                            }
                        }else{
                            flex_row.append(Cards[i]);
    
                            if((i + 1) % 2 == 0){
                                Rows.push(flex_row);
                                flex_row = '';
                                flex_row = document.createElement('div');
                                flex_row.classList.add('flex_row')
                            }
                        }
                        
                    }
                }
    
                for (let i = 0; i < Rows.length; i++) {
                    Skills_Container.append(Rows[i]);
                    
                }

            });

            for (let i = 0; i < Rows.length; i++) {
                Skills_Container.append(Rows[i]);
                
            }
            




        }

        getSkills();






    }
    
    render() {
        
        

        
        
        return (
            <>
                <div className="hero">
                    <div className="AboutMe">
                        {/* Background */}
                            <div data-magnet data-magnet-range="-50" className="svg-wrapper">
                                <svg viewBox="0 0 200 200">
                                    <defs>
                                        {/* <!-- Our gradient fill #gradient --> */}
                                        <linearGradient id="gradient" gradientTransform="rotate(90)">
                                        {/* <!-- Use CSS custom properties for the start / stop colors of the gradient --> */}
                                        <stop id="gradientStop1" offset="0%" stop-color="var(--startColor)" />
                                        <stop id="gradientStop2 " offset="100%" stop-color="var(--stopColor)" />
                                        </linearGradient>
                                    </defs>
                                    {/* <!-- Add a path with an empty data attribute, fill it with the gradient we defined earlier --> */}
                                    <path id='pathSvg' d="" fill="url('#gradient')"></path>
                                </svg>
                            </div>
                        {/* End of Background */}

                        <div className="aboutme-info">
                            <h3 className='color-grad'>Hi, Im 4lireza</h3>
                            <p>
                                i am a full stack web developer,
                                <br />
                                i’v been working as a web developer since 2019,
                                <br />
                                i am currently working as a freelancer.
                            </p>
                        </div>
                        
                    </div>
                    <div className="showcase">
                        <img src={showcase} alt="" id='showcaseImage' />
                    </div>
                </div>
                <div className="mySkills">
                    <br/>
                    <h4 >My Skills</h4> 
                    <div className="skills_container" id='skills_container' >
                        
                        {/* <div className="flex_row">
                            <div className="skill_card">
                                <img src={css} alt="" />
                                <p>Css</p>
                            </div>
                            <div className="skill_card">
                                <img src={css} alt="" />
                                <p>Css</p>
                            </div>
                            <div className="skill_card">
                                <img src={css} alt="" />
                                <p>CssadA:NDio 1o</p>
                            </div>
                            <div className="skill_card">
                                <img src={css} alt="" />
                                <p>Css</p>
                            </div>
                            <div className="skill_card">
                                <img src={css} alt="" />
                                <p>Css</p>
                            </div>
                        </div> */}
                    </div>
                    {/* <div className="slider">
                        <OwlCarousel className='owl-theme' items={6} loop margin={10} dots={false} autoplay={true} slideTransition="linear" autoplaySpeed={6000} smartSpeed={6000} fluidSpeed={6000} autoplayTimeout={4000} responsive={{
                            // breakpoint from 0 up
                            0 : {
                                items : 1,
                            },
                            // breakpoint from 0 up
                            410 : {
                                items : 2,
                            },
                            // breakpoint from 0 up
                            650 : {
                                items : 3,
                            },
                            820 : {
                                items : 4,
                            },
                            // breakpoint from 480 up
                            1050 : {
                                items : 5,
                            },
                            // breakpoint from 768 up
                            1250 : {
                                items : 6,
                            }
                        }}>
                            <div className="slider-item">
                                <img src={css} alt="" />
                            </div>
                            <div className="slider-item">
                                <img src={jquery} alt="" />
                            </div>
                            <div className="slider-item">
                                <img src={js} alt="" />
                            </div>
                            <div className="slider-item">
                                <img src={php} alt="" />
                            </div>
                            <div className="slider-item">
                                <img src={python} alt="" />
                            </div>
                            <div className="slider-item">
                                <img src={react} alt="" />
                            </div>
                            <div className="slider-item">
                                <img src={html} alt="" />
                            </div>
                            <div className="slider-item">
                                <img src={bootstrap} alt="" />
                            </div>
                            <div className="slider-item">
                                <img src={sass} alt="" />
                            </div>
                            <div className="slider-item">
                                <img src={less} alt="" />
                            </div>
                            <div className="slider-item">
                                <img src={mysql} alt="" />
                            </div>
                            <div className="slider-item">
                                <img src={materialize} alt="" />
                            </div>
                        </OwlCarousel>
                    </div> */}
                    
                </div>
            </>
        );
    }
}
 
export default AboutMe;