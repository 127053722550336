import './NotFound.css';
import image from './404.png'

function NotFound() {
    return ( 
        <div className="notfound_container">
            <img className='notfound_image' src={image} alt="notfound" />
            <h2>Something Went Wrong... | 404 Error</h2>
        </div>
     );
}

export default NotFound;