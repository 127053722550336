import React from 'react';
import { useState } from 'react';
import './Nav.css';
import {useLocation} from 'react-router-dom';
import Logo from '../../assets/img/logo.png'

function Nav() {

    const location = useLocation();


    const [state,setState] = useState({
        navToggle : false,
        currentNav : location.pathname,
    })

    const toggleNav = ()=>{
        setState({...state , navToggle : !state.navToggle});
    }

    // console.log(state.currentNav);


    return ( 
        <nav className="navbar">
            <div className="logo">
                <img src={Logo} alt="" />
            </div>
            <div className={state.navToggle ? 'nav active' : 'nav'} id='nav'>
                <span className="nav-toggle" onClick={toggleNav}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M0 96C0 78.33 14.33 64 32 64H416C433.7 64 448 78.33 448 96C448 113.7 433.7 128 416 128H32C14.33 128 0 113.7 0 96zM0 256C0 238.3 14.33 224 32 224H416C433.7 224 448 238.3 448 256C448 273.7 433.7 288 416 288H32C14.33 288 0 273.7 0 256zM416 448H32C14.33 448 0 433.7 0 416C0 398.3 14.33 384 32 384H416C433.7 384 448 398.3 448 416C448 433.7 433.7 448 416 448z"/></svg>
                </span>
                <span>
                    <a href="/"><h6 className={state.currentNav == '/' ? 'nav-item active' : 'nav-item'}>About Me</h6></a>
                </span>
                <span>
                    <a href="/MyWork"><h6 className={state.currentNav == '/MyWork' ? 'nav-item active' : 'nav-item'}>My Work</h6></a>
                </span>
                <span>
                    <a href="/ContactMe"><h6 className={state.currentNav == '/ContactMe' ? 'nav-item active' : 'nav-item'}>Contact Me</h6></a>
                </span>

            </div>
            <div className="contact-info">
                <a href="mailto:4lirezajalali@gmail.com"><span>4lirezajalali@gmail.com</span></a>
                <span>|</span>
                <a href="tel:+989395897133"><span>+989395897133</span></a>
            </div>
        </nav>
     );
}

export default Nav;
