import './App.css';
import Nav from './Components/NavBar/Nav';
import {BrowserRouter as Router, Routes , Route} from 'react-router-dom'
import AboutMe from './Components/AboutMe/AboutMe';
import Footer from './Components/Footer/Footer';
import MyWork from './Components/MyWork/MyWork';
import ContactMe from './Components/ContactMe/ContactMe';
import NotFound from './Components/NotFound/NotFound';

function App() {

  return (
    
    <div className='Container'>
      
        <Router>
        <Nav/>
          <Routes>
              <Route path={'/'} exact element={<AboutMe/>} />
              <Route path={'/MyWork'} exact element={<MyWork/>} />
              <Route path={'/ContactMe'} exact element={<ContactMe/>} />
              <Route path={'*'} exact element={<NotFound/>} />
          </Routes>
        </Router>
      <Footer/>

    </div>

    

  );
}

export default App;
