import React, { Component } from 'react';
import './MyWork.css';
// import placeholder from '../../assets/img/logo2.png'
import DualRing from '../../assets/img/icons/DualRing.svg'

class MyWork extends Component {

    componentDidMount(){


        // onload set setting
        const project_container = document.querySelector('#project_container');
        const project_container_width = project_container.offsetWidth;
        const screenWidth = window.innerWidth;

        const ratio = project_container_width / screenWidth;
        const zoomRatio = screenWidth / 1920;

        const iframs = document.querySelectorAll('.preview');
        iframs.forEach(iframe=>{
            iframe.setAttribute("style", `-moz-transform: scale(${ratio.toFixed(3)});-o-transform: scale(${ratio.toFixed(3)});-webkit-transform: scale(${ratio.toFixed(3)});zoom: ${zoomRatio.toFixed(3)};height:${ ((1480 - (zoomRatio.toFixed(3) * 1480)) + 400 + 1480)}px`);
            // iframe.setAttribute("style", `-moz-transform: scale(${ratio.toFixed(3)});-o-transform: scale(${ratio.toFixed(3)});-webkit-transform: scale(${ratio.toFixed(3)});zoom: ${zoomRatio.toFixed(3)};height:${ zoomRatio > 1 ? (zoomRatio.toFixed(3) * 1480) + 1480 : zoomRatio < 1 ? ((1480 - (zoomRatio.toFixed(3) * 1480)) + 1480) : 1480 }px`);
        })


        // set settings in case of change of screen size 
        window.addEventListener('resize', ()=>{
            const project_container = document.querySelector('#project_container');
            const project_container_width = project_container.offsetWidth;
            const screenWidth = window.innerWidth;

            const ratio = project_container_width / screenWidth;
            const zoomRatio = screenWidth / 1920;

            const iframs = document.querySelectorAll('.preview');
            iframs.forEach(iframe=>{
                iframe.setAttribute("style", `-moz-transform: scale(${ratio.toFixed(3)});-o-transform: scale(${ratio.toFixed(3)});-webkit-transform: scale(${ratio.toFixed(3)});zoom: ${zoomRatio.toFixed(3)};height:${ ((1480 - (zoomRatio.toFixed(3) * 1480)) + 400 + 1480) }px`);
            })

        });


        const iframes = document.querySelectorAll('.preview');
        iframes.forEach(iframe=>{
            iframe.addEventListener("load", function() {
                const parent = iframe.parentElement;
                const spinner = parent.querySelector('#spinner');
                spinner.style.display = "none";
            });
        })
    }



    render() {

        const myWorks = [
            {
                url : 'https://e-market.4lireza.ir',
                title : 'E-Market Using NextJs'
            },
            {
                url : 'https://namava.4lireza.ir',
                title : 'Namava FE Using React'
            },
            {
                url : 'https://music.4lireza.ir',
                title : 'MusicSphere Using Nextjs'
            },
            {
                url : 'http://gym.pro.devslab.ir/index.php',
                title : 'MyGym Using PHP'
            },
            {
                url : 'https://store.4lireza.ir',
                title : 'Comfy Using JS'
            },
            {
                url : 'https://chatapp.4lireza.ir',
                title : 'ChatApp Using React'
            },
            
        ];

        return (
            <>
                <h3 className='title'>You can See Some of My Projects Down Below</h3>
                <div className="Projects-Wrapper">
                    <div className="Projects">
                        
                        {myWorks.map(Work=>(
                            <div className="Project">
                                <a href={Work.url} target="_blank">
                                    <div className="Project_Live" id='project_container' >
                                        <div className="spinner" id="spinner">
                                            <img src={DualRing} alt="loading" />
                                        </div>
                                        <iframe className="preview" src={Work.url} scrolling="no" ></iframe>
                                    </div>
                                    <div className="product_detail">{Work.title}</div>
                                </a>
                            </div>
                        ))}
                        
                        {/* <div className="Project">
                            <img src={placeholder} alt="" />
                        </div> */}
                    </div>
                </div>
            </>
        );
    }
}
 
export default MyWork;