import React, { Component } from 'react';
import './ContactMe.css';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';
import Infinity from '../../assets/img/icons/Infinity.svg'

class ContactMe extends Component {

    state = {
        isLoading : false,
    }

    render() { 

        const SERVICE_ID = "service_1ediwk7";
        const TEMPLATE_ID = "template_mkrbozc";
        const USER_ID = "C0mPvMwMFFm4DUVlx";


        const handleOnSubmit = (e) => {
            this.setState({isLoading : true})
            e.preventDefault();
            emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID)
              .then((result) => {
                this.setState({isLoading : false})
                console.log(result.text);
                Swal.fire({
                  icon: 'success',
                  title: 'Message Sent Successfully'
                })
              }, (error) => {
                this.setState({isLoading : false})
                console.log(error.text);
                Swal.fire({
                  icon: 'error',
                  title: 'Ooops, something went wrong',
                  text: error.text,
                })
              });
            e.target.reset()
        };


        return (
            <div className="contactMe-Container">
                <div className="contactMe-Wrapper">
                    <div className="contactMe">
                        <div className="SendMessage">
                            <form onSubmit={handleOnSubmit} >
                                <h5>Send Me a Message</h5>
                                <h6>Name:</h6>
                                <input type="text"  name="from_name" id="from_name" required />
                                <h6>Email:</h6>
                                <input type="text" name="from_email" id="from_email" required />
                                <h6>Message:</h6>
                                <textarea cols="30" rows="10"  name="message" id="message" required ></textarea>
                                <button type='submit' >
                                    { this.state.isLoading ? 
                                        <img src={Infinity}  className="send_message_loading" />
                                    :
                                        <>
                                        Send
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                            <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"/>
                                        </svg>
                                        <div className="shine"></div>
                                        </>
                                     }
                                </button>
                            </form>
                        </div>
                        <div className="ContactInfo">
                            <h5>My Contact Info</h5>
                            <h6>My Email</h6>
                            <a href='mailto:4lirezajalali@gmail.com'>
                                <p> 
                                    <svg
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                        height="1em"
                                        width="1em"
                                        className='icons'
                                        >
                                        <path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6m-2 0l-8 5-8-5h16m0 12H4V8l8 5 8-5v10z" />
                                    </svg>
                                    4lirezajalali@gmail.com
                                </p>
                            </a>
                            <h6>My Phone Number</h6>
                            <a href='tel:+989395897133' ><p>
                            <svg fill="none" viewBox="0 0 24 24" height="1em" width="1em" className='icons'>
                                <path
                                    fill="currentColor"
                                    d="M22 12A10.002 10.002 0 0012 2v2a8.003 8.003 0 017.391 4.938A8 8 0 0120 12h2zM2 10V5a1 1 0 011-1h5a1 1 0 011 1v4a1 1 0 01-1 1H6a8 8 0 008 8v-2a1 1 0 011-1h4a1 1 0 011 1v5a1 1 0 01-1 1h-5C7.373 22 2 16.627 2 10z"
                                />
                                <path
                                    fill="currentColor"
                                    d="M17.543 9.704c.302.728.457 1.508.457 2.296h-1.8A4.199 4.199 0 0012 7.8V6a6 6 0 015.543 3.704z"
                                />
                            </svg>
                                +989395897133
                                </p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default ContactMe;